.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  animation: appear 200ms ease-in;
  overflow-y: scroll;
}

@keyframes appear {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.App h1 {
  color: #fff;
  margin-bottom: 20px;
}

.destination-container {
  margin-top: 20px;
}

.destination-card {
  padding: 20px;
  max-width: 200px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 20px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.1px;
  transition: 200ms ease-in;
}

.destination-card:not(:first-child) {
  margin-top: 20px;
}

.destination-card:hover {
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
}

.time-card {
  color: #fff;
}

.card-container {
  position: relative;
  max-height: 60vh;
  overflow-y: scroll;
}

.card-container::-webkit-scrollbar {
  display: none;
}

.card {
  position: relative;
  padding: 20px 20px;
  min-width: 280px;
  max-width: 500px;
  background-color: #000;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  font-weight: 700;
  letter-spacing: 1.1px;
  transition: 200ms ease-in;
  margin-bottom: 20px;
  animation: fade 400ms ease-in;
  animation-fill-mode: forwards;
}

@keyframes fade {
  from {
    border-color: rgba(255, 255, 255, 1);
  }
  to {
    border-color: rgba(255, 255, 255, 0.5);
  }
}

.card p {
  font-size: 18px;
  position: relative;
  font-weight: 400;
  margin: 0 0 40px 0;
}

.card p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0%;
  height: 2px;
  background-color: #fff;
  animation: slidein 200ms ease-in-out 100ms;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    width: 0%;
  }
  to {
    width: 20%;
  }
}

.card span {
  display: block;
  text-align: center;
  margin-top: 20px;
  background-color: #fff;
  color: #000;
  border-radius: 50px;
  padding: 10px 20px;
}

.card .waittime {
  animation: update 1s ease-in;
}

@keyframes update {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #55efc4;
  }
  100% {
    background-color: #fff;
  }
}

.back-button {
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  letter-spacing: 1.1px;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 20px;
  transition: all 100ms ease-in;
}

.back-button:hover {
  cursor: pointer;
  transform: scale(1.05);
}
