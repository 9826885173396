html,
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: calc(100vh - calc(100vh - 100%));
  user-select: none;
  user-zoom: none;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
